import { useContext } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";
import classnames from "classnames";

import { Action, Category } from "constants/events";
import ConfigContext from "context/ConfigContext";
import FinderResultsContext from "context/FinderResultsContext";
import MapContext from "context/MapContext";
import { Store } from "custom-types/Store";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import useTrackImpression from "hooks/useTrackImpression";
import {
  hasAnyDelivery,
  hasLeaflyDelivery,
  hasLeaflyPickup,
} from "utils/dispensaryUtils";
import { getRoundedDistance } from "utils/distance";
import {
  getEventLabel,
  getMerchId,
  trackStoreImpression,
} from "utils/eventTracking";
import isMobileDevice from "utils/isMobileDevice";
import { getSplitKey } from "utils/split/getSplitKey";
import { getStoreUrl } from "utils/storeUrlUtils";

import StoreCard from "./StoreCard";

type Props = {
  store: Store;
  index: number;
  compact?: boolean;
  id: number | string;
  hideOnMobile?: boolean;
  retailType?: string;
  isUnorderedList?: boolean;
  finderMigrationFlag?: boolean;
  reduceMapMarkersFlag?: boolean;
};

const StoreCardWithData = ({
  store,
  index,
  compact,
  id,
  hideOnMobile,
  retailType,
  isUnorderedList,
  finderMigrationFlag,
  reduceMapMarkersFlag,
}: Props) => {
  const { storeRoute } = useContext(ConfigContext);

  const countryCode = useDomainCountryCode();

  const { strainFilters, availableFilters } = useContext(FinderResultsContext);
  const { publishEvent } = useEventTracker();

  const {
    handleMouseOverStore,
    handleMouseAwayStore,
    hoveredStore,
    selectedStore,
  } = useContext(MapContext);

  const hasDelivery = hasLeaflyDelivery(store);
  const hasPickup = hasLeaflyPickup(store);
  const hasNeither = !hasPickup && !hasDelivery;

  const trackingData = {
    label: store.name,
    origination: store.tier < 5 ? `List ${store.tier + 1}` : "List",
    ...store,
    customDimensions: {
      merchandisingCampaignId: getMerchId(store, "platinumplacement"),
      ...(store?.deals && store.deals[0] ? { dealId: store.deals[0].id } : {}),
      dispensaryDistance: getRoundedDistance(store.distanceMi, countryCode),
    },
  };

  const [setRef] = useTrackImpression(
    () => trackStoreImpression({ ...trackingData }),
    {
      threshold: 0.5,
    },
  );

  const handleCardClick = () => {
    if (store.tier < 900) {
      sendClientSideSplitTrackEvent(
        {
          eventType: "webFinder_storeCardClick_frontend",
          properties: {
            // using `hasAnyDelivery` here for parity with the map marker event
            hasDelivery: !!hasAnyDelivery(store),
            isMobile: isMobileDevice(),
            reduceMapMarkersFlag: !!reduceMapMarkersFlag,
            retailType: store.retailType,
            row: index,
            tier: store.tier,
          },
        },
        getSplitKey(),
      );
      publishEvent({
        action: Action.click,
        category: Category.finder,
        dispensaryId: store.id,
        label: getEventLabel(
          trackingData.origination,
          trackingData.tier,
          trackingData.mapMarkerLevel,
        ) as string,
        merchandisingCampaignId:
          trackingData.customDimensions.merchandisingCampaignId,
        ...(trackingData.customDimensions?.dealId
          ? { dealId: trackingData.customDimensions?.dealId }
          : {}),
      });
    } else {
      publishEvent({
        action: "click",
        category: "finder",
        dispensaryId: store.id,
        label: "info listing",
      });
    }
  };

  const storeParams = {
    availableFilters,
    hasMenu: (store?.reservableMenuItemCount && !hasNeither) ?? false,
    storeRoute,
    storeSlug: store.slug,
    ...((strainFilters?.strain || strainFilters?.product_type) && {
      strainFilters,
    }),
  };
  const isHovered = hoveredStore === store.id;
  const isSelected = selectedStore?.id === store.id;

  return (
    <StoreCard
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
      ref={setRef as any}
      compact={compact ?? false}
      retailType={retailType}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
      href={getStoreUrl(storeParams as any)}
      id={id}
      onClick={handleCardClick}
      ctaOnClick={handleCardClick}
      onMouseEnter={() => handleMouseOverStore(store.id)}
      onMouseLeave={handleMouseAwayStore}
      platinumDeals="on_plat"
      selected={isSelected}
      store={store}
      hideOnMobile={hideOnMobile}
      className={classnames("border border-light-grey", {
        "my-sm": !compact,
        "shadow-none": !isHovered && !isSelected,
      })}
      isUnorderedList={isUnorderedList}
      finderMigrationFlag={finderMigrationFlag}
    />
  );
};

export default StoreCardWithData;
