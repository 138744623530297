import { createContext, useContext, useEffect, useState } from "react";
import { Coords } from "google-map-react";
import pick from "lodash/pick";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";

import getDispensaries, {
  GetFinderDispensariesQueryParams,
} from "api/requests/getFinderDispensaries";
import { MEDICAL_FILTER, RECREATIONAL_FILTER } from "constants/finder/filters";
import ConfigContext from "context/ConfigContext";
import LocationContext from "context/LocationContext";
import MapContext from "context/MapContext";
import {
  AvailableFilters,
  AvailableSort,
  AvailableSorts,
  CategorizedFilter,
  DisplayFilter,
  FilterOption,
  SortParam,
  StrainFilters,
} from "custom-types/AvailableFilters";
import { Coordinates } from "custom-types/Coordinates";
import { DispensaryTagsEnum } from "custom-types/Dispensary";
import { Location } from "custom-types/Location";
import { Store, StoresResponse } from "custom-types/Store";
import useBrowserStorage from "hooks/useBrowserStorage";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { setUserMenuPreference } from "redux/action-creators/user";
import { AppThunkDispatch } from "redux/store";
import isDispensaryDeliveryOnly from "utils/dispensary/isDispensaryDeliveryOnly";
import { getMilesBetween } from "utils/distance";
import {
  flattenFilters,
  getCurrentStrainFilter,
  getDispensaryFilters,
  getSubFilters,
  removeAllStrainFilters,
  toggleStrainFilter,
  updateFilters,
} from "utils/filterUtils";
import {
  getLocationRadius,
  isAreaSearch,
  setSortQueryParam,
} from "utils/finder/finderResultUtils";
import { getFittedBounds } from "utils/finder/getFittedBounds";
import { BoundingBox } from "utils/finder/mapUtils";
import { selectSpotlight } from "utils/finder/selectSpotlight";
import { hasFullAddress } from "utils/hasFullAddress";
import {
  clearAllFinderFilterQueryParams,
  clearQueryParams,
  setPageQueryParam,
} from "utils/nextRouterUtils";

const DEFAULT_SORT_OPTION: AvailableSort = {
  display: "Recommended",
  param: "default",
};

type ActiveFilters = string[];

export type FinderResultsContextProps = {
  activeFilters: ActiveFilters;
  availableFilters: AvailableFilters;
  availableSorts: AvailableSorts;
  finderLocation: Location;
  getPage: (page?: number) => number;
  handleClearAllFilters: () => void;
  handlePageChange: (page: number) => void;
  handleSetSortBy: (sort: AvailableSort) => void;
  handleToggleFilter: (filterName: string) => void;
  loadingStores: boolean;
  mapMarkers: Store[];
  pageCount: number;
  pageInitialized: boolean;
  promotedNewStores: Store[];
  savedRadius?: number | null;
  searchThisArea: (page: number) => void;
  setActiveFilters: (activeFilters: string[]) => void;
  sortBy: AvailableSort;
  sponsoredStores: Store[];
  stores: Store[];
  storesCount: number;
  storesError: boolean;
  strainFilters: StrainFilters;
};

export const finderResultsContext: FinderResultsContextProps = {
  activeFilters: [],
  availableFilters: [],
  availableSorts: [],
  finderLocation: {},
  getPage: (page = 1) => page,
  handleClearAllFilters: () => {},
  handlePageChange: () => {},
  handleSetSortBy: () => {},
  handleToggleFilter: () => {},
  loadingStores: true,
  mapMarkers: [],
  pageCount: 1,
  pageInitialized: false,
  promotedNewStores: [],
  savedRadius: null,
  searchThisArea: () => {},
  setActiveFilters: () => {},
  sortBy: DEFAULT_SORT_OPTION,
  sponsoredStores: [],
  stores: [],
  storesCount: 0,
  storesError: false,
  strainFilters: { product_type: null, strain: null },
};

const FinderResultsContext =
  createContext<FinderResultsContextProps>(finderResultsContext);

FinderResultsContext.displayName = "FinderResultsContext";

const MIN_RADIUS = 20;

export const FinderResultsProvider = ({
  finderMigrationFlag,
  reduceMapMarkersFlag,
  children,
}: {
  finderMigrationFlag?: boolean;
  reduceMapMarkersFlag?: boolean;
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const {
    retailType,
    isLocationPage,
    organization: { id },
  } = useContext(ConfigContext);

  const { query } = router;
  const { strain, product_type, organization_id = id, filter } = query;

  const { finderLocation } = useContext(LocationContext);

  const {
    boundingBox,
    handleSetMapMarkers,
    initMapMarkers,
    mapCenter,
    mapContainerEl,
    mapInitialized,
    mapMarkers,
    selectedStore,
    setLoadingMapMarkers,
    setMapCenter,
    setSelectedStore,
    setShowSearchButton,
    setSpotlight,
    setZoomLevel,
    zoomLevel,
  } = useContext(MapContext);

  const defaultSort = DEFAULT_SORT_OPTION;

  const initialDispensaryFilters = filter
    ? Array.isArray(filter)
      ? filter.map((f) => decodeURIComponent(f))
      : [decodeURIComponent(filter)]
    : [];

  const initialStrainFilters: StrainFilters = {
    product_type: typeof product_type === "string" ? product_type : null,
    strain: typeof strain === "string" ? strain : null,
  };

  const initialOrganizationFilter: FilterOption<number> | null = organization_id
    ? {
        display: "",
        param:
          typeof organization_id === "string"
            ? parseInt(organization_id)
            : (organization_id as number),
      }
    : null;

  const initialActiveFilters = [
    ...initialDispensaryFilters,
    ...(initialStrainFilters.strain ? [initialStrainFilters.strain] : []),
    ...(initialStrainFilters.product_type
      ? [initialStrainFilters.product_type]
      : []),
  ];

  const dispatch = useDispatch<AppThunkDispatch>();

  const sortOptionsByQueryParam: AvailableSort = {
    display: "",
    param: router.query.sort as SortParam,
  };

  const [activeFilters, setActiveFilters] =
    useState<string[]>(initialActiveFilters);

  const [prevActiveFilters, setPrevActiveFilters] =
    useState<string[]>(initialActiveFilters);

  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>(
    [],
  );

  const [availableSorts, setAvailableSorts] = useState<AvailableSorts>([]);

  const [loadingStores, setLoadingStores] = useState(true);

  const [pageCount, setPageCount] = useState(1);

  const [pageInitialized, setPageInitialized] = useState(false);

  const [organizationFilter, setOrganizationFilter] =
    useState<FilterOption<number> | null>(initialOrganizationFilter);

  const radius =
    query.radius && typeof query.radius === "string"
      ? parseInt(query.radius?.slice(0, -2))
      : null;
  const [savedRadius, setSavedRadius] = useState<number | null>(
    radius ?? finderLocation.radius ?? null,
  );

  const [sortBy, setSortBy] = useState<AvailableSort>(
    router.query.sort ? sortOptionsByQueryParam : defaultSort,
  );

  const [previousSort, setPreviousSort] = useState<AvailableSort>(
    router.query.sort ? sortOptionsByQueryParam : defaultSort,
  );

  const [sponsoredStores, setSponsoredStores] = useState<Store[]>([]);

  const [stores, setStores] = useState<Store[]>([]);
  const [storesCount, setStoresCount] = useState(0);

  const [storesError, setStoresError] = useState(false);

  const [promotedNewStores, setPromotedNewStores] = useState<Store[]>([]);
  const [newStoreExcludeIds, setNewStoreExcludeIds] = useState<number[]>([]);

  const [strainFilters, setStrainFilters] =
    useState<StrainFilters>(initialStrainFilters);

  const [shouldUpdateMapLocation, setShouldUpdateMapLocation] = useState(false);

  const [spotlightCycle, setSpotlightCycle] = useBrowserStorage<number[]>(
    "local",
    "spotlightCycle",
    [],
  );

  const _getFilterLabel = (
    availableFilters: AvailableFilters,
    filterName: string | number,
  ) =>
    flattenFilters(availableFilters).find(
      (f: CategorizedFilter) => f.param === filterName,
    )?.display;

  // setters
  const _handleSetStores = ({
    stores,
    sponsoredStores,
    spotlight,
    error,
  }: {
    stores: Store[];
    sponsoredStores: Store[];
    spotlight: Store[] | null;
    error: boolean;
  }) => {
    setStoresError(error);
    setStores(stores);
    setSponsoredStores(sponsoredStores);
    setLoadingStores(false);
    if (spotlight) {
      setSpotlight(
        selectSpotlight(spotlight, spotlightCycle, setSpotlightCycle),
      );
    }
  };

  const _handleSetOrganizationFilter = (availableFilters: AvailableFilters) => {
    if (
      organizationFilter?.param &&
      !organizationFilter?.display &&
      availableFilters.length > 0
    ) {
      const display = _getFilterLabel(
        availableFilters,
        organizationFilter?.param,
      );
      if (display) {
        setOrganizationFilter({ display, param: organizationFilter.param });
        setActiveFilters([...activeFilters, display]);
      }
    }
  };

  const _handleSetFiltersAndSorts = (
    availableFilters: AvailableFilters,
    availableSorts: AvailableSorts,
  ) => {
    _handleSetOrganizationFilter(availableFilters);
    // maybe find a better place to filter out pickup and delivery for the tab work (dis-881)
    setAvailableFilters(
      availableFilters.filter((f) => f.param !== "reservable_type"),
    );
    setAvailableSorts(availableSorts);
  };

  const countryCode = useDomainCountryCode();

  const _setUserPreferenceCookie = async (filterName: string) => {
    const medRecFilterNames = [MEDICAL_FILTER, RECREATIONAL_FILTER];
    if (!medRecFilterNames.includes(filterName)) {
      return;
    }

    filterName === MEDICAL_FILTER
      ? dispatch(setUserMenuPreference("Med"))
      : dispatch(setUserMenuPreference("Rec"));
  };

  const handleToggleFilter = (filterName: string) => {
    const strainNameFilters = getSubFilters(availableFilters, "strain_name");
    const productTypeFilters = getSubFilters(availableFilters, "product_type");
    const organizationFilters = getSubFilters(
      availableFilters,
      "organization_type",
    );

    if (strainNameFilters.find((f: DisplayFilter) => f.param === filterName)) {
      const currentStrainFilter = getCurrentStrainFilter(
        filterName,
        strainFilters.strain,
      );

      setStrainFilters({
        product_type: !currentStrainFilter
          ? currentStrainFilter
          : strainFilters.product_type,
        strain: currentStrainFilter,
      });
      if (!currentStrainFilter) {
        return setActiveFilters(
          removeAllStrainFilters(strainFilters, activeFilters),
        );
      } else {
        return setActiveFilters(
          toggleStrainFilter({
            activeFilters,
            filter: {
              key: "strain",
              value: filterName,
            },
            strainFilters,
          }),
        );
      }
    }

    if (productTypeFilters.find((f: DisplayFilter) => f.param === filterName)) {
      setStrainFilters({
        product_type: getCurrentStrainFilter(
          filterName,
          strainFilters.product_type,
        ),
        strain: strainFilters.strain,
      });
      return setActiveFilters(
        toggleStrainFilter({
          activeFilters,
          filter: {
            key: "product_type",
            value: filterName,
          },
          strainFilters,
        }),
      );
    }

    if (
      organizationFilters.find((f: DisplayFilter) => f.display === filterName)
    ) {
      setOrganizationFilter(null);
      clearQueryParams(["organization_id"], true);
      return setActiveFilters(
        activeFilters.filter((filter) => filter !== filterName),
      );
    }

    // Allows user to clear the strain filter using the ActiveFilter button when no results
    if (
      strainFilters.strain &&
      !strainNameFilters.find(
        (f: DisplayFilter) => f.param === strainFilters.strain,
      )
    ) {
      setStrainFilters({
        product_type: null,
        strain: null,
      });
      return setActiveFilters(
        removeAllStrainFilters(strainFilters, activeFilters),
      );
    }
    const label = _getFilterLabel(availableFilters, filterName);
    const newActiveFilters = updateFilters({
      activeFilters,
      filterName,
      label,
      specialFilters: {
        ...strainFilters,
        ...(organizationFilter?.display && {
          organization: organizationFilter.display,
        }),
      },
    });

    setActiveFilters(newActiveFilters);
    _setUserPreferenceCookie(filterName);
  };

  const handleClearAllFilters = () => {
    clearAllFinderFilterQueryParams();
    setStrainFilters({ product_type: null, strain: null });
    setOrganizationFilter(null);
    setActiveFilters([]);
  };

  const handleSetSortBy = (sort: AvailableSort) => {
    setSortBy(sort);
    setSortQueryParam(sort);
  };

  const processFilters = () => {
    const { strain, product_type } = strainFilters;
    const filters: string[] =
      getDispensaryFilters(activeFilters, {
        ...(organizationFilter?.display && {
          organization: organizationFilter.display,
        }),
        ...(strain && { strain }),
        ...(product_type && {
          product_type,
        }),
      }) || [];

    return { filters, product_type, strain };
  };

  const _handleMapBounds = async (
    mapContainerEl: HTMLDivElement | undefined,
    coords: Coords,
    stores: Store[],
  ): Promise<BoundingBox> => {
    const { center, zoom, newBounds } = await getFittedBounds({
      center: coords,
      mapContainerEl,
      stores,
    });

    setMapCenter({ lat: center.lat, lon: center.lng });
    setZoomLevel(zoom);
    return newBounds;
  };

  const reduceMapMarkers = (stores: Store[], coords: Coordinates) => {
    return stores.map((store) => {
      if (!store.flags.includes("delivery")) {
        return store;
      }
      const closestMarker = store.locations.reduce((acc, curr) => {
        const distanceToAcc = getMilesBetween(coords, acc);
        const distanceToCurr = getMilesBetween(coords, curr);
        if (distanceToAcc > distanceToCurr) {
          return curr;
        }
        return acc;
      });

      const locations: Coordinates[] = [];

      if (
        !isDispensaryDeliveryOnly(store.flags as DispensaryTagsEnum[]) &&
        store.primaryLocation
      ) {
        locations.push(store.primaryLocation);
      }

      locations.push(closestMarker);

      return { ...store, locations };
    });
  };

  const _fetchDispensaries = async ({
    newStoreExcludeIds,
    location = finderLocation,
    radius = savedRadius,
    page = 1,
    ids,
    promoteNewStores,
    boundingBox,
  }: {
    location?: Location;
    radius?: number | string | null;
    page?: number;
    ids?: number[];
    promoteNewStores?: boolean;
    newStoreExcludeIds?: number[];
    boundingBox?: BoundingBox;
  }): Promise<StoresResponse> => {
    const { filters, product_type, strain } = processFilters();
    const organization_id = organizationFilter?.param;

    const { coordinates } = location;

    let searchRadius = radius;

    if (typeof radius === "string") {
      const parsedRadius = parseInt(radius.slice(0, -2));
      searchRadius = parsedRadius < MIN_RADIUS ? MIN_RADIUS : parsedRadius;
    } else {
      searchRadius = Math.max(radius ?? 0, MIN_RADIUS);
    }

    let params: GetFinderDispensariesQueryParams = {
      countryCode,
      geo_query_type: "point",
      lat: coordinates?.lat,
      lon: coordinates?.lon,
      newDefaultSort: "default",
      page,
      retailType,
      sort: sortBy?.param,
      state: finderLocation.state,
      ...(strain && typeof strain === "string" && { strain }),
      ...(strain &&
        product_type &&
        typeof product_type === "string" && { product_type }),
      ...((!ids || ids?.length === 0) &&
        filters &&
        typeof filters === "object" &&
        filters?.length > 0 && { filter: filters }),
      ...(searchRadius && {
        radius: searchRadius,
      }),
      ...(ids && ids?.length > 0 && { ids }),
      ...(organization_id && { organization_id }),
      flags: ["dispensary"],
      include_spotlight: true,
    };

    const shouldPromoteNewStores =
      sortBy?.param === "default" && promoteNewStores;

    if (shouldPromoteNewStores) {
      params = { ...params, promoteNewStores };
    }

    if (newStoreExcludeIds) {
      params = { ...params, newStoreExcludeIds };
    }

    // Search This Area Params
    if (boundingBox) {
      const { ...areaSearchParams } = params;
      params = {
        ...areaSearchParams,
        boundingBox,
        geo_query_type: "bounding-box",
        lat: finderLocation.coordinates?.lat,
        lon: finderLocation.coordinates?.lon,
      };
    }

    if (isLocationPage) {
      params = { ...params, isLocationPage };
    }

    // Delivery Zone Params
    if (
      !ids &&
      filters?.includes("delivery") &&
      hasFullAddress(location) &&
      !boundingBox
    ) {
      params = {
        ...params,
        geo_query_type: "intersects",
      };
    }

    const { stores, sponsoredStores, promotedNewStores, ...rest } =
      await getDispensaries(params, {
        useConsumerAPIFlagEnabled: Boolean(finderMigrationFlag),
      });

    const coords: Coordinates = params.boundingBox
      ? mapCenter
      : pick(params, ["lat", "lon"]);

    const storeFn = (_stores: Store[]) =>
      reduceMapMarkersFlag ? reduceMapMarkers(_stores, coords) : _stores;

    return {
      promotedNewStores: storeFn(promotedNewStores),
      sponsoredStores: storeFn(sponsoredStores),
      stores: storeFn(stores),
      ...rest,
    };
  };

  const _hasPickupFilter = () => {
    const { filters } = processFilters();
    return filters.includes("pickup");
  };

  const _fetchDispensariesByServiceArea = async (
    currUserLocation: Location,
    page: number,
  ) => _fetchDispensariesByRadius(currUserLocation, null, page);

  const _fetchDispensariesByRadius = async (
    currUserLocation: Location,
    radius: number | string | null,
    page: number,
    resetNewStoreExcludeIds = false,
  ) => {
    const {
      stores,
      sponsoredStores,
      pageCount,
      storesCount,
      spotlight = null,
      availableSorts = [],
      availableFilters = [],
      error = false,
      promotedNewStores: promotedNewStoresResult = [],
    } = await _fetchDispensaries({
      location: currUserLocation,
      newStoreExcludeIds: resetNewStoreExcludeIds ? [] : newStoreExcludeIds,
      page,
      promoteNewStores: page === 1,
      radius,
    });

    const sortedStores = [
      ...sponsoredStores,
      ...stores,
      ...promotedNewStoresResult,
    ];
    setLoadingStores(true);
    setLoadingMapMarkers(true);
    _handleSetStores({ error, sponsoredStores, spotlight, stores });
    setPromotedNewStores(promotedNewStoresResult);
    if (page === 1) {
      setNewStoreExcludeIds(promotedNewStoresResult.map((store) => store.id));
    }
    initMapMarkers(currUserLocation, sortedStores, _hasPickupFilter());
    _handleSetFiltersAndSorts(availableFilters, availableSorts);
    setPageCount(pageCount);
    setStoresCount(storesCount);
    setSelectedStore(null);
  };

  const _initializeDispensaries = async (
    currUserLocation: Location,
    radius: number | string | null,
    page: number,
    resetNewStoreExcludeIds?: boolean,
  ) => {
    setShowSearchButton(false);

    const { filters } = processFilters();

    if (filters?.includes("delivery") && hasFullAddress(currUserLocation)) {
      await _fetchDispensariesByServiceArea(currUserLocation, page);
    } else {
      await _fetchDispensariesByRadius(
        currUserLocation,
        radius,
        page,
        resetNewStoreExcludeIds,
      );
    }

    setPageInitialized(true);
    setSavedRadius(
      typeof radius === "string"
        ? Number(radius.slice(0, radius.lastIndexOf("mi")))
        : radius,
    );
  };

  // Request for dispensaries by page
  //...alternatively can be used to fetch dispensaries when radius is already in available in app state.
  const _getPaginatedDispensaries = async (
    location: Location,
    page: number,
  ) => {
    setShowSearchButton(false);
    setLoadingStores(true);
    const {
      stores,
      sponsoredStores,
      pageCount,
      storesCount,
      spotlight = null,
      availableFilters = [],
      availableSorts = [],
      error = false,
      promotedNewStores: promotedNewStoresResult = [],
    } = await _fetchDispensaries({
      location,
      newStoreExcludeIds,
      page,
      promoteNewStores: page === 1,
      radius: savedRadius,
    });

    _handleSetFiltersAndSorts(availableFilters, availableSorts);
    _handleSetStores({ error, sponsoredStores, spotlight, stores });
    setPromotedNewStores(promotedNewStoresResult);
    if (page === 1) {
      setNewStoreExcludeIds(promotedNewStoresResult.map((store) => store.id));
    }

    const allStores = [
      ...stores,
      ...sponsoredStores,
      ...promotedNewStoresResult,
    ];

    const mapBounds = await _handleMapBounds(
      mapContainerEl,
      {
        lat: location.coordinates?.lat,
        lng: location.coordinates?.lon,
      },
      allStores,
    );

    if (mapBounds) {
      handleSetMapMarkers(allStores, mapBounds, _hasPickupFilter());
    }

    setPageCount(pageCount);
    setStoresCount(storesCount);
    if (
      selectedStore &&
      !allStores.find((store) => store.id === selectedStore.id)
    ) {
      setSelectedStore(null);
    }
    setLoadingStores(false);
  };

  const _getStoresInThisArea = async ({
    boundingBox,
    page = 1,
    resetNewStoreExcludeIds = false,
  }: {
    boundingBox?: BoundingBox;
    page?: number;
    resetNewStoreExcludeIds?: boolean;
  }) => {
    setLoadingStores(true);
    setLoadingMapMarkers(true);
    setShowSearchButton(false);
    const {
      stores,
      sponsoredStores,
      pageCount,
      storesCount,
      spotlight = null,
      availableFilters = [],
      availableSorts = [],
      error = false,
      promotedNewStores: promotedNewStoresResult = [],
    } = await _fetchDispensaries({
      boundingBox,
      newStoreExcludeIds: resetNewStoreExcludeIds ? [] : newStoreExcludeIds,
      page,
      promoteNewStores: page === 1,
    });

    _handleSetFiltersAndSorts(availableFilters, availableSorts);
    _handleSetStores({ error, sponsoredStores, spotlight, stores });
    const allStores = [
      ...stores,
      ...sponsoredStores,
      ...promotedNewStoresResult,
    ];

    setPromotedNewStores(promotedNewStoresResult);
    if (page === 1) {
      setNewStoreExcludeIds(promotedNewStoresResult.map((store) => store.id));
    }
    handleSetMapMarkers(allStores, boundingBox, _hasPickupFilter());
    setPageCount(pageCount);
    setStoresCount(storesCount);

    if (
      selectedStore &&
      !allStores.find((store) => store.id === selectedStore.id)
    ) {
      setSelectedStore(null);
    }

    setLoadingStores(false);
  };

  const handlePageChange = (page: number) => {
    setPageQueryParam(page, false, page === 1 ? ["page"] : []);
    if (isAreaSearch(router.query, boundingBox)) {
      _getStoresInThisArea({ boundingBox, page });
    } else {
      _getPaginatedDispensaries(finderLocation, page);
    }
  };

  const searchThisArea = async (page: number) => {
    _getStoresInThisArea({
      boundingBox,
      page,
      resetNewStoreExcludeIds: true,
    });
  };

  // Initalize a new set of results
  useEffect(() => {
    async function handleInitDispensaries(page: number) {
      let radius: number | null = null;
      if (savedRadius && !pageInitialized) {
        radius = savedRadius;
      } else if (router.query.radius) {
        radius = parseInt(router.query.radius.slice(0, -2) as string);
        setSavedRadius(radius);
      } else {
        const radiusAsString = await getLocationRadius(
          finderLocation,
          MIN_RADIUS,
        );

        radius = parseInt(radiusAsString.slice(0, -2));
      }

      _initializeDispensaries(finderLocation, radius, page, true);
    }

    if (mapInitialized) {
      if (isAreaSearch(router.query, boundingBox)) {
        _getStoresInThisArea({ boundingBox, page: getPage() });
      } else {
        handleInitDispensaries(getPage());
      }
    }
  }, [mapInitialized, finderLocation, retailType]);

  // Search this area and paging
  // also handles browser back button for paging and map search
  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (!as.match(/lat=/)) {
        setShouldUpdateMapLocation(false);
        setShowSearchButton(false);
        let page = getPage();

        if (router.asPath.match(/page=/) && !as.match(/page=/)) {
          page = 1;
        }
        if (router.asPath.match(/lat=/) || router.asPath.match(/page=/)) {
          _initializeDispensaries(finderLocation, savedRadius, page);
        }
      } else if (as !== router.asPath) {
        setShouldUpdateMapLocation(true);
      }
      return true;
    });

    if (shouldUpdateMapLocation) {
      const { lat: currentLat, lon: currentLon } = mapCenter;
      const { lat, lon, zoom } = router.query;
      if (
        currentLat !== parseFloat(lat as string) ||
        currentLon !== parseFloat(lon as string) ||
        zoomLevel !== parseFloat(zoom as string)
      ) {
        setMapCenter({
          lat: parseFloat(lat as string),
          lon: parseFloat(lon as string),
        });

        setZoomLevel(parseInt(zoom as string));
      }
      setShowSearchButton(true);
    }
  }, [router.query.lat, router.query.page, boundingBox]);

  // Used the saved radius for filter and sort fetches
  useEffect(() => {
    if (prevActiveFilters.length === 0 && organizationFilter?.param) {
      setPreviousSort(sortBy);
      return setPrevActiveFilters(activeFilters);
    }

    if (activeFilters !== prevActiveFilters || sortBy !== previousSort) {
      if (isAreaSearch(router.query, boundingBox)) {
        _getStoresInThisArea({
          boundingBox,
          page: 1,
          resetNewStoreExcludeIds: true,
        });
      } else {
        _initializeDispensaries(finderLocation, savedRadius, 1, true);
      }
    }
    setPreviousSort(sortBy);
    setPrevActiveFilters(activeFilters);
  }, [activeFilters, sortBy]);

  function getPage() {
    return Number(router.query.page) || 1;
  }

  return (
    <FinderResultsContext.Provider
      value={{
        activeFilters: activeFilters.filter(
          (filter) => !["pickup", "delivery"].includes(filter),
        ),
        availableFilters,
        availableSorts,
        finderLocation,
        getPage,
        handleClearAllFilters,
        handlePageChange,
        handleSetSortBy,
        handleToggleFilter,
        loadingStores,
        mapMarkers,
        pageCount,
        pageInitialized,
        promotedNewStores,
        savedRadius,
        searchThisArea,
        setActiveFilters,
        sortBy,
        sponsoredStores,
        stores,
        storesCount,
        storesError,
        strainFilters,
      }}
    >
      {children}
    </FinderResultsContext.Provider>
  );
};

export default FinderResultsContext;
