import React, { useEffect, useState } from "react";
import {
  getSplitTreatmentsInGetInitialProps,
  sendClientSideSplitTrackEvent,
} from "@leafly-com/split-next";
import isEmpty from "lodash/isEmpty";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";

import { Action, Category } from "constants/events";
import ContextProviders from "context/ContextProviders";
import { ViewEnum, ViewType } from "custom-types/Finder";
import { FinderPageContext as FinderPageProps } from "custom-types/FinderPage";
import { useEventTracker } from "hooks/useEventTracker";
import { COLLAPSE_HEADER, EXPAND_HEADER } from "redux/reducers/header";
import getFinderContextData from "utils/finder/getContextData";
import { getSplitKey } from "utils/split/getSplitKey";

import FinderHead from "components/Finder/FinderHead";
import FinderSprites from "components/Finder/FinderSprites/FinderSprites";
import ListingsWrapper from "components/Finder/ListingsWrapper";
import MapWrapper from "components/Finder/MapWrapper";
import TrackImpression from "components/TrackImpression";

const FinderPage: React.FC<FinderPageProps> = ({
  locationContext,
  configContext,
  mapContext,
  finderSiteMapFlag,
  finderMigrationFlag,
  reduceMapMarkersFlag,
}) => {
  const dispatch = useDispatch();

  const { isLocationPage, organization } = configContext;

  const { query, pathname, replace } = useRouter();

  useEffect(() => {
    if (
      !isLocationPage &&
      query?.location &&
      query.location.length > 0 &&
      isEmpty(organization)
    ) {
      replace({ pathname });
    }
  }, [organization]);

  const [listingPosition, setListingPosition] = useState<ViewType>(
    ViewEnum.HALF,
  );

  const handleSetListingPosition = (type: ViewType) => {
    if (type === ViewEnum.MAP || type === ViewEnum.LIST) {
      dispatch({ type: COLLAPSE_HEADER });
    } else {
      dispatch({ type: EXPAND_HEADER });
    }

    setListingPosition(type);
  };

  const { trackEvent } = useEventTracker();

  function trackMobileViewClickEvent(listingPosition: ViewEnum) {
    if (listingPosition === ViewEnum.LIST) {
      trackEvent(Category.finder, Action.click, `${ViewEnum.LIST} view`);
    } else if (listingPosition === ViewEnum.MAP) {
      trackEvent(Category.finder, Action.click, `${ViewEnum.MAP} view`);
    }
  }
  return (
    <>
      <ContextProviders
        configContext={configContext}
        mapContext={mapContext}
        locationContext={locationContext}
        finderMigrationFlag={finderMigrationFlag}
        reduceMapMarkersFlag={reduceMapMarkersFlag}
      >
        <FinderHead />
        <TrackImpression
          trackingFunction={() => {
            sendClientSideSplitTrackEvent(
              "NL-1493_reduceMapMarkers_finder_impression",
              getSplitKey(),
            );
          }}
          className="finder-page flex flex-col bg-leafly-white z-10"
        >
          <FinderSprites />
          <ListingsWrapper
            listingPosition={listingPosition}
            setListingPosition={handleSetListingPosition}
            trackMobileViewClickEvent={trackMobileViewClickEvent}
            finderSiteMapFlag={finderSiteMapFlag}
            finderMigrationFlag={finderMigrationFlag}
            reduceMapMarkersFlag={reduceMapMarkersFlag}
          />
          <MapWrapper
            listingPosition={listingPosition}
            setListingPosition={handleSetListingPosition}
            trackMobileViewClickEvent={trackMobileViewClickEvent}
            finderMigrationFlag={finderMigrationFlag}
            reduceMapMarkersFlag={reduceMapMarkersFlag}
          />
        </TrackImpression>
      </ContextProviders>
      <style jsx global>{`
        .finder-page {
          --finder-desktop-listing-width: 380px;
          --finder-desktop-min-breakpoint: 1023px;
          --finder-collapsed-header-height: 48px;
          --finder-mobile-bottom-touchpoint: 104px;
          --finder-mobile-top-touchpoint: var(--finder-collapsed-header-height);
        }
        html {
          height: 100%;
        }
        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        ::-webkit-scrollbar-track {
          background: gainsboro;
          border-radius: 5px;
        }
        ::-webkit-scrollbar-thumb {
          background: var(--color-green);
          border-radius: 5px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: var(--color-default);
        }
        .active-filters {
          -webkit-overflow-scrolling: touch;
        }
        .active-filters::-webkit-scrollbar {
          display: none;
        }
        .scrollSnapWrapper {
          scroll-snap-points-x: repeat(272px);
          scroll-snap-type: x mandatory;
          scroll-padding: 16px;
          list-style: none;
        }
        .removeScrollbar {
          overflow-x: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
        .removeScrollbar::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      `}</style>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<FinderPageProps> = async (
  context,
) => {
  const { query } = context;
  const contextData = await getFinderContextData(context);

  const { organization } = contextData.configContext;

  if (isEmpty(organization) && query?.location?.includes("locations")) {
    return { notFound: true };
  }

  const {
    finderSiteMap_flag: { treatment: finderSiteMapFlagTreatment },
    "NL-823_finderMigration_flag": { treatment: finderMigrationFlagTreatment },
    "NL-1363_reduceMapMarkers_flag": { treatment: reduceMapMarkersTreatment },
  } = await getSplitTreatmentsInGetInitialProps(
    [
      "finderSiteMap_flag",
      "NL-823_finderMigration_flag",
      "NL-1363_reduceMapMarkers_flag",
    ],
    getSplitKey(context),
  );

  const finderSiteMapFlag = finderSiteMapFlagTreatment === "on";
  const finderMigrationFlag = finderMigrationFlagTreatment === "on";
  const reduceMapMarkersFlag = reduceMapMarkersTreatment === "on";

  return {
    props: {
      ...contextData,
      // The following props are used only in _app.tsx
      finderMigrationFlag,
      finderSiteMapFlag,
      hideFooter: true,
      hideHeader: false,
      reduceMapMarkersFlag,
    },
  };
};

export default FinderPage;
